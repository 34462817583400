.container {
  margin: 24px 0;
}

.listItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fullWidth {
  flex-direction: column;
  align-items: flex-start;
}
