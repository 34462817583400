.container {
  padding: 25px;
  margin: 0 auto;
  max-width: 1000px;
}

@media screen and (min-width: 765px) {
  .container {
    padding: 50px;
  }
}
